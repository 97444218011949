// This address points to a dummy ERC20 contract deployed on Ethereum Mainnet,
// Goerli, Kovan, Rinkeby and Ropsten. Replace it with your smart contracts.

import ContractAddresses from './abis/contract-address.json'

const addresses = ContractAddresses

// const addresses = {
//   ceaErc20: "0xc1C0472c0C80bCcDC7F5D01A376Bd97a734B8815",
// };

export default addresses;
