import React, { useState, useEffect } from 'react'
import { Contract } from '@ethersproject/contracts'
import { getDefaultProvider } from '@ethersproject/providers'
// import { useQuery } from '@apollo/react-hooks'

import Image from 'react-bootstrap/Image'
import logo from './assets/zeus-assets/logo/logo-zeus-red.svg'
import useWeb3Modal from './hooks/useWeb3Modal'
import WalletButton from './components/WalletButton'
import { ethers } from 'ethers'

import { addresses, abis } from '@project/contracts'

// import GET_TRANSFERS from './graphql/subgraph'
import Header from './components/Header'
import Features from './components/Features'
import Gallery from './components/Gallery'
import TimeLine from './components/TimeLine'
import RoadMap from './components/RoadMap'
import FAQ from './components/FAQ'
import CallToAction from './components/CallToAction'
import MintAction from './components/MintAction'
import Footer from './components/Footer'

console.log(`🚀 ~ addresses`, addresses)
console.log(`🚀 ~ abis`, abis)

// async function readOnChainData() {
//   // Should replace with the end-user wallet, e.g. Metamask
//   const defaultProvider = getDefaultProvider()
//   // Create an instance of an ethers.js Contract
//   // Read more about ethers.js on https://docs.ethers.io/v5/api/contract/contract/
//   const _nftContract = new Contract(addresses.NFT, abis.NFT, defaultProvider)
//   // A pre-defined address that owns some NFT tokens
//   const tokenBalance = await _nftContract.balanceOf('0xf39fd6e51aad88f6f4ce6ab8827279cfffb92266')
//   console.log({ tokenBalance: tokenBalance.toString() })
// }



function App() {
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal()
  console.log(`🚀 ~ provider`, provider)

  // Auto pop wallet connect
  // useEffect(() => {
  // 	if (provider) {
  // 		const ethersProvider = new ethers.providers.Web3Provider(provider);
  //     console.log(`🚀 ~ ethersProvider`, ethersProvider.getSigner())
  // 	}
  // }, [provider])

	const [userAccount, setUserAccount] = useState<any>()

  const [transData, setTransData] = useState<any>()
  loadNFTContract()
  // // Used for SubGraph
  // const { loading, error, data } = useQuery(GET_TRANSFERS)
  // React.useEffect(() => {
  //   if (!loading && !error && data && data.transfers) {
  //     console.log({ transfers: data.transfers })
  //   }
  // }, [loading, error, data])

  const [nftData, setNftData] = useState<any>()

  // const _getNftData = async () => {
  //   console.log(`🚀 ~ _getNftData`)
  //   const name = await _nftContract.name()
  //   const symbol = await _nftContract.symbol()
  //   const price = await _nftContract.price()
  //   const newPrice = ethers.utils.formatUnits(price)

  //   setNftData({ name, symbol, newPrice })
  // }
  // _getNftData()

  // useEffect(() => {
  //   if (_nftContract) {

  //   }
  // }, [_nftContract])

  console.log(`🚀 ~ nftData`, nftData)

	async function loadNFTContract() {
		// Should replace with the end-user wallet, e.g. Metamask
		const defaultProvider = getDefaultProvider()
		console.log(`🚀 ~ defaultProvider`, defaultProvider)
		// Create an instance of an ethers.js Contract
		// Read more about ethers.js on https://docs.ethers.io/v5/api/contract/contract/

		const _nftContract = new ethers.Contract(addresses.NFT, abis.NFT.abi, defaultProvider)
		console.log(`🚀 ~ _nftContract`, _nftContract)

		const accounts = await (window as any).ethereum.request({
			method: 'eth_requestAccounts',
		})
		console.log(`🚀 ~ accounts`, accounts)
		setUserAccount(accounts[0])

		// A pre-defined address that owns some NFT tokens
		// const tokenBalance = await _nftContract.balanceOf(accounts[0])
		// console.log({ tokenBalance: tokenBalance.toString() })
	}

  // This method just clears part of the state.
  function _dismissTransactionError() {
    setTransData({ transactionError: undefined })
  }

  const _mintTokens = async (quantity: number) => {
    // const _nftContract = new Contract(addresses.ceaErc20, abis.erc20, defaultProvider)
    // const tokenBalance = await _nftContract.balanceOf(userAccount)
    // console.log({ tokenBalance: tokenBalance.toString() })
    // console.log(`🚀 ~ _nftContract2`, _nftContract)
    // // Sending a transaction is a complex operation:
    // //   - The user can reject it
    // //   - It can fail before reaching the ethereum network (i.e. if the user
    // //     doesn't have ETH for paying for the transactions gas)
    // //   - It has to be mined, so it isn't immediately confirmed.
    // //     Note that some testing networks, like Hardhat Network, do mine
    // //     transactions immediately, but your dapp should be prepared for
    // //     other networks.
    // //   - It can fail once mined.
    // //
    // // This method handles all of those things, so keep reading to learn how to
    // // do it.
    // try {
    //   // If a transaction fails, we save that error in the component's state.
    //   // We only save one such error, so before sending a second transaction, we
    //   // clear it.
    //   _dismissTransactionError()
    //   // We send the transaction, and save its hash in the Dapp's state. This
    //   // way we can indicate that we are waiting for it to be mined.
    //   // const tx = await _nftContract.createToken({value: ethers.utils.parseEther(nftData.newPrice)})
    //   const totalPrice = quantity * nftData.newPrice
    //   const tx = await _nftContract.mint(quantity, { value: ethers.utils.parseEther(totalPrice.toString()) })
    //   setTransData({ txBeingSent: tx.hash })
    //   // We use .wait() to wait for the transaction to be mined. This method
    //   // returns the transaction's receipt.
    //   const receipt = await tx.wait()
    //   // The receipt, contains a status flag, which is 0 to indicate an error.
    //   if (receipt.status === 0) {
    //     // We can't know the exact error that made the transaction fail when it
    //     // was mined, so we throw this generic one.
    //     throw new Error('Transaction failed')
    //   }
    //   // If we got here, the transaction was successful, so you may want to
    //   // update your state. Here, we update the user's balance.
    //   // await _updateBalance()
    // } catch (error) {
    //   // We check the error code to see if this error was produced because the
    //   // user rejected a tx. If that's the case, we do nothing.
    //   if (error === 'ERROR_CODE_TX_REJECTED_BY_USER') {
    //     return
    //   }
    //   // Other errors are logged and stored in the Dapp's state. This is used to
    //   // show them to the user, and for debugging.
    //   console.error(error)
    //   setTransData({ transactionError: error })
    // } finally {
    //   // If we leave the try/catch, we aren't sending a tx anymore, so we clear
    //   // this part of the state.
    //   setTransData({ txBeingSent: undefined })
    // }
  }

  return (
    <>
      <div>
        <nav className="navbar navbar-light navbar-expand-lg" id="nav">
          <div className="container-fluid">
            <a className="navbar-brand h4 text-decoration-none" href="https://mydomain.com">
              <Image src={logo} alt="{NFT Collection}" width="100" />
            </a>
            <div className="d-lg-none">
              <button className="btn btn-sm navbar-burger">
                <svg className="d-block" width={16} height={16} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <title>Mobile menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav ms-auto me-4">
                <li className="nav-item">
                  <a className="nav-link" href="#features">
                    Features
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#nfts">
                    NFTs
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="dao#">
                    DAO
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#faq">
                    FAQ
                  </a>
                </li>
              </ul>
              <div className="d-none d-lg-block">
                <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
              </div>
            </div>
          </div>
        </nav>
      </div>

      <Header />
      {provider && nftData && <MintAction mintTokens={(quantity: number) => _mintTokens(quantity)} tokenSymbol={nftData.symbol} />}
      <Features />
      <Gallery />
      <TimeLine />
      <RoadMap />
      <FAQ />
      <CallToAction />
      <Footer />
    </>
  )
}

export default App
