import React from 'react'

interface Props {
  mintTokens: any
  tokenSymbol: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MintAction = (props: Props) => {
  const { mintTokens, tokenSymbol } = props

  return (
    <div>
      <h4>Mint</h4>
      <form
        onSubmit={(event) => {
          // This function just calls the mintTokens callback with the
          // form's data.
          event.preventDefault()

          const formData = new FormData(event.currentTarget)
          const quantity = formData.get('quantity')

          if (quantity) {
            mintTokens(quantity)
          }
        }}
      >
        <div className="form-group">
          <label>How many? {tokenSymbol}</label>
          <input className="form-control" type="number" step="1" name="quantity" placeholder="1" required />
        </div>
        <div className="form-group">
          <input className="btn btn-primary" type="submit" value="MINT NFT" />
        </div>
      </form>
    </div>
  )
}

export default MintAction
